import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import clsx from 'clsx';
import styles from './devops-tack-stack.module.css';

interface SVGProps {
  title: string;
  publicURL: string;
}

interface IMGProps {
  title: string;
  childImageSharp: {
    fluid: {
      src: string;
      srcSet: string;
    };
  };
}

type TDevopsTech =
  | 'cloudFront'
  | 'lambda'
  | 'ec2'
  | 'awsRds'
  | 'awsS3'
  | 'awsIam'
  | 'cloudFormation'
  | 'cloudWatch'
  | 'awsVpc'
  | 'awsEcs'
  | 'awsRoute53'
  | 'codepipeline'
  | 'amazonSystemsManage'
  | 'awsCodebuild';

interface Props {
  list: ReadonlyArray<TDevopsTech>;
  listStyle?: string;
  itemStyle?: string;
}

function RenderSVG({ publicURL, title }: SVGProps) {
  return (
    <img
      src={publicURL}
      alt={title}
      className={clsx(styles.logo, {
        [styles.large]: title === 'amazon' || title === 'googleMeet',
      })}
    />
  );
}

function RenderIMG({ title, childImageSharp }: IMGProps) {
  return (
    <img
      alt={title}
      className={styles.logo}
      src={childImageSharp.fluid.src}
      srcSet={childImageSharp.fluid.srcSet}
    />
  );
}

function DevopsTechnology({ list, listStyle, itemStyle }: Props) {
  const data = useStaticQuery(graphql`
    query {
      cloudFront: file(relativePath: { eq: "aws-cloudfront.svg" }) {
        publicURL
      }
      lambda: file(relativePath: { eq: "awsLambda.svg" }) {
        publicURL
      }
      ec2: file(relativePath: { eq: "ec2.svg" }) {
        publicURL
      }
      awsRds: file(relativePath: { eq: "aws-rds.svg" }) {
        publicURL
      }
      awsS3: file(relativePath: { eq: "awsS3.svg" }) {
        publicURL
      }
      awsIam: file(relativePath: { eq: "awsIam.svg" }) {
        publicURL
      }
      cloudFormation: file(relativePath: { eq: "CloudFormation.svg" }) {
        publicURL
      }
      cloudWatch: file(relativePath: { eq: "Cloudwatch.svg" }) {
        publicURL
      }
      awsVpc: file(relativePath: { eq: "awsVpc.svg" }) {
        publicURL
      }
      awsEcs: file(relativePath: { eq: "awsEcs.svg" }) {
        publicURL
      }
      awsRoute53: file(relativePath: { eq: "awsRoute53.svg" }) {
        publicURL
      }
      codepipeline: file(relativePath: { eq: "Codepipeline.svg" }) {
        publicURL
      }
      amazonSystemsManage: file(relativePath: { eq: "amazonSystemsManage.svg" }) {
        publicURL
      }
      awsCodebuild: file(relativePath: { eq: "awsCodebuild.svg" }) {
        publicURL
      }
    }
  `);

  const devopsTech = React.useMemo(() => {
    const {
      cloudFront,
      lambda,
      ec2,
      awsRds,
      awsS3,
      awsIam,
      cloudFormation,
      cloudWatch,
      awsVpc,
      awsEcs,
      awsRoute53,
      codepipeline,
      amazonSystemsManage,
      awsCodebuild,
    } = data;
    return {
      cloudFront: { title: 'CloudFront', ...cloudFront },
      lambda: { title: 'Lambda', ...lambda },
      ec2: { title: 'EC2', ...ec2 },
      awsRds: { title: 'RDS', ...awsRds },
      awsS3: { title: 'S3', ...awsS3 },
      awsIam: { title: 'IAM', ...awsIam },
      cloudFormation: { title: 'CloudFormation', ...cloudFormation },
      cloudWatch: { title: 'Cloudwatch', ...cloudWatch },
      awsVpc: { title: 'VPC', ...awsVpc },
      awsEcs: { title: 'ECS', ...awsEcs },
      awsRoute53: { title: 'Route53', ...awsRoute53 },
      codepipeline: { title: 'Codepipeline', ...codepipeline },
      amazonSystemsManage: { title: 'Systems Manage', ...amazonSystemsManage },
      awsCodebuild: { title: 'Codebuild', ...awsCodebuild },
    };
  }, []);

  return (
    <ul className={clsx(styles.list, listStyle)}>
      {list.map((e) => {
        if ('publicURL' in devopsTech[e]) {
          return (
            <li key={devopsTech[e].title} className={clsx(styles.techItem, itemStyle)}>
              <RenderSVG title={devopsTech[e].title} publicURL={devopsTech[e].publicURL} />
              <span className={styles.techTitle}>{devopsTech[e].title}</span>
            </li>
          );
        }
        if ('childImageSharp' in devopsTech[e]) {
          return (
            <li key={devopsTech[e].title} className={clsx(styles.techItem, itemStyle)}>
              <RenderIMG
                title={devopsTech[e].title}
                childImageSharp={devopsTech[e].childImageSharp}
              />
              <span className={styles.techTitle}>{devopsTech[e].title}</span>
            </li>
          );
        }
      })}
    </ul>
  );
}

export default DevopsTechnology;
