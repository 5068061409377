import React from 'react';
import clsx from 'clsx';
import styles from '../services.module.css';
import Bullets from 'components/bullets';
import Booking from 'components/feedback';
import Layout from '../components/layout';
import devOpsBullets from './devOpsBullets.json';
import { SuperTours, TradersAlloy, Amped } from 'components/carousel';
import loadable from '@loadable/component';
import Tabs from '../../tabs';
import DevopsTechnology from './devops-tack-stack';

import { meta } from '../../../metaData';
import FAQ from 'components/faq';
import { useFaqData } from 'hooks/useFaqData';

const LIST = [
  {
    title: 'CONTINUOUS PLANNING',
  },
  {
    title: 'CONTINUOUS DEVELOPMENT',
  },
  {
    title: 'CONTINUOUS INTEGRATION',
  },
  {
    title: 'CONTINUOUS DEPLOYMENT',
  },
  {
    title: 'CONTINUOUS TESTING',
  },
  {
    title: 'CONTINUOUS DELIVERY & MONITORING',
  },
  {
    title: 'CONTINUOUS FEEDBACK',
  },
];

interface Props {
  location: Location;
}

const MobileAppDevelopment = ({ location }: Props) => {
  const faqData = useFaqData('allStrapiService', 'DevOps and Cloud Services');
  const SwiperSlider = loadable(() => import('components/swiper-slider'));

  return (
    <Layout
      title="DevOps and Cloud Services"
      subtitle="Reinforce cooperation between the development, engineering, security, and management teams to optimize your software development life cycle (SDLC) with smooth and well-structured DevOps by Brocoders."
      btnText="REVAMP YOUR INFRASTRUCTURE"
      metaData={{ main: meta.devOps, faq: faqData?.list }}
      pathname={location.pathname}
    >
      <section className={clsx('section', styles.mvp)}>
        <div className="inner">
          <h2 className="title">What we offer</h2>
          <p className="subtitle">
            Faster product development and deployment, enhanced quality and efficiency, increased
            delivery capacity, stability, and resilience to failures — all this and more can be
            brought to you by implementing the best cloud architecture and DevOps practices, applied
            by our cross-functional team with 9+ years of experience.
          </p>
          <Bullets list={devOpsBullets} className={styles.bullets} />
        </div>
      </section>
      <section className={styles.section}>
        <div className="inner">
          <h2 className="title">DevOps model we adhere to</h2>
          <p className="subtitle">
            Brocoders team is a dedicated follower of the Agile methodology and is governed by the
            DevOps taxonomy of “Continuous Everything” and its eight capabilities. It's a mindset, a
            team strategy, and a software development practice where members of a team integrate
            their work frequently. Let’s review what processes each one of them entails.
          </p>
          <Tabs btnClassName="tabs__btn_30" isDevOpsPage>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <ul className={styles.list}>
                  <li>
                    <b>Intro meeting</b>: discuss the project details, agree on project
                    requirements, and make sure they are complete.
                  </li>
                  <li>
                    <b>Discovery and analysis</b>: assess the current architecture, infrastructure,
                    and processes along with the existing hardware and software, identify the
                    performance bottlenecks and the room for improvement.
                  </li>
                  <li>
                    <b>Continuously updated plan</b>: develop a step-by-step roadmap to optimize the
                    existing IT infrastructure, security, and resource usage.
                  </li>
                  <li>
                    <b>DevOps adoption</b>: map out the DevOps culture building and adoption plan
                    for all organizational levels, from stakeholders to employees.
                  </li>
                </ul>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>Depends on project</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>Plan for software development cycle (SDLC)</p>
                  </li>
                  <li>
                    <h4>Participants</h4>
                    <p>All project team</p>
                  </li>
                  <li>
                    <h4>Apps</h4>
                    <p>
                      Jira
                      <br />
                      Draw.io
                    </p>
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <ul className={styles.list}>
                  <li>
                    <b>Environment automation</b>: establish a robust automation environment for
                    builds, continuous integration & deployment.
                  </li>
                  <li>
                    <b>Configuration</b>: automated configuration and continuous management of
                    servers, testing, staging, and production environments.
                  </li>
                  <li>
                    <b>Security</b>: regular security audits of networks, environments, and
                    applications.
                  </li>
                  <li>Load balancing and autoscaling.</li>
                </ul>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Tools</h4>
                    <div>
                      AWS:
                      <ul className={styles.sublist}>
                        <li>EC2</li>
                        <li>CloudFormation</li> <li>Systems Manager</li> <li>Bash</li>
                        <li>Python</li> <li>IAM</li>
                      </ul>
                      Digital Ocean
                      <br />
                      Vultr
                      <br />
                      Google Cloud
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <ul className={styles.list}>
                  <li>
                    <b>Pull request</b>: run changes through the CI/CD pipeline by committing
                    finished pieces of code to a code repository and submitting a pull request to
                    merge this piece with a shared codebase.
                  </li>
                  <li>
                    <b>Code review</b>: review submitted changes and approve the pull request if
                    everything looks smooth. The manual test is quick and easy, but effective at
                    identifying issues in the far reaches.
                  </li>
                  <li>
                    <b>Automated tests</b>: a pull request triggers an automated process, which
                    builds the codebase and runs a series of end-to-end, integration and unit tests
                    to identify any regressions. If the build or any of the tests fail, the pull
                    request is denied and the piece of code is returned to the developer for
                    revision. By continuously checking code changes and running builds and tests, we
                    can minimize integration issues that arise when working on a shared codebase,
                    harness collaboration enabling parallel development, and highlight breaking bugs
                    early in the development lifecycle.
                  </li>
                  <li>
                    <b>Repeat</b>: the cycle of running a source code through various processes to
                    ensure it’s in good fix will repeat until the code is ready.
                  </li>
                </ul>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Tools</h4>
                    <div>
                      AWS:
                      <ul className={styles.sublist}>
                        <li>CodePipeline</li>
                        <li>CodeBuild</li> <li>CodeStar</li>
                      </ul>
                      Jenkins
                      <br />
                      CircleCI
                      <br />
                      Git
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <ul className={styles.list}>
                  <li>
                    <b>Automatic deployment</b>: once a build is approved, it is automatically
                    deployed to a staging (test) environment for scrupulous out-of-band testing. A
                    series of manual — User Acceptance Testing (UAT) — and automated tests —
                    security scanning, performance, regression, smoke, black/white box, and load
                    testing — are performed there along with the environment configuration
                    validation.
                  </li>
                  <li>
                    <b>Information exchange</b>: ensure proper information exchange between all
                    system components.
                  </li>
                  <li>
                    <b>Pre-production tests</b>: ensure the optimal code performance in the staging
                    environment by creating a precise replica of the production environment.
                  </li>
                </ul>
                <p>
                  You may think of this stage as a test-bed for new changes testing without
                  interrupting developers workflow or affecting the production environment.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Tools</h4>
                    <div>
                      AWS:
                      <ul className={styles.sublist}>
                        <li>CodeDeploy</li>
                      </ul>
                      Jenkins
                      <br />
                      CircleCI
                      <br />
                      GithubActions
                      <br />
                      Docker
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  This stage is an extension of the continuous integration one in that it ensures
                  that all code changes get automatically deployed to a testing environment after
                  development. Hence the reduced deployment risk: as an organization is deploying in
                  smaller increments, there are fewer things that can go wrong and it’s easier to
                  fix an issue should it appear. Furthermore, continuous deployment enables fast and
                  continuous feedback from User Acceptance testers.
                </p>
                <p>
                  Another purpose of continuous deployment is to automate and manage the
                  provisioning and configuration of deployment environments, as well as testing of
                  these environments for stability, performance and security compliance.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Tools</h4>
                    <div>
                      AWS:
                      <ul className={styles.sublist}>
                        <li>CodeDeploy</li>
                      </ul>
                      Jenkins
                      <br />
                      CircleCI
                      <br />
                      GithubActions
                      <br />
                      Docker
                    </div>
                  </li>
                </ul>
              </div>
            </li>

            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  This phase is a milestone of the DevOps pipeline: it’s when we say the build is
                  ready for deployment into the production environment. By this stage, each code
                  change has passed a series of manual and automated tests, and the operations team
                  can be confident that breaking issues and regressions are unlikely.
                </p>
                <p>
                  This is the nirvana of DevOps and is how organizations manage to deploy multiple
                  product versions daily.
                </p>
                <ul className={styles.list}>
                  <li>
                    <b>Pre-launch checklist</b>: double-check everything from the ground up and go
                    through the pre-launch checklist to make sure the product is fully functional.{' '}
                  </li>
                  <li>
                    <b>Launch</b>: deploy to production and monitor the performance.
                  </li>
                </ul>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Tools</h4>
                    <div>
                      AWS:
                      <ul className={styles.sublist}>
                        <li>CloudWatch</li>
                      </ul>
                      Prometheus
                      <br />
                      Grafana
                      <br />
                      Sentry
                      <br />
                      Slack
                      <br />
                      Logz
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  Continuous feedback ties the ends of the SDLC loop, supplying data and analytics
                  for continuous planning, thus is just as important as CI and CD.
                </p>
                <ul className={styles.list}>
                  <li>
                    <b>Operations</b>: make sure that everything is running like clockwork.
                  </li>
                  <li>
                    <b>Funnel feedback</b>: build a way for users to provide feedback along with the
                    toolset to collect and triage it for further planning. Nobody knows what they
                    want better than users, they are the world’s best testing team, devoting far
                    more hours to testing than the DevOps pipeline ever could.
                  </li>
                </ul>
                <p>
                  Along with building a powerful continuous feedback loop, the point of DevOps
                  culture is to release new features and fixes as quickly as possible so that the
                  organization can collect feedback from customers, stakeholders, and analysts as
                  quickly as possible to make better decisions when designing the next set of
                  changes and eventually create a better product.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Participants</h4>
                    <p>
                      Project manager
                      <br />
                      Stakeholders
                      <br />
                      DevOps
                      <br />
                      Developers
                    </p>
                  </li>
                </ul>
              </div>
            </li>
          </Tabs>
        </div>
      </section>
      <section className={styles.carousel}>
        <SwiperSlider>
          <SuperTours />
          <Amped />
          <TradersAlloy slideTitle="CI/CD pipeline for a Fintech startup" />
        </SwiperSlider>
      </section>
      <section className={styles.section}>
        <div className="inner">
          <div className={styles.technicalStack}>
            <h4 className={styles.technicalTitle}>Technical Stack</h4>
            <DevopsTechnology
              list={[
                'ec2',
                'awsRds',
                'awsS3',
                'awsIam',
                'lambda',
                'cloudFormation',
                'cloudFront',
                'cloudWatch',
                'awsVpc',
                'awsEcs',
                'awsRoute53',
                'codepipeline',
                'amazonSystemsManage',
                'awsCodebuild',
              ]}
              listStyle={styles.technicalList}
              itemStyle={styles.technicalItem}
            />
          </div>
        </div>
      </section>
      <section className="inner">
        <FAQ data={faqData} />
      </section>
      <Booking />
    </Layout>
  );
};

export default MobileAppDevelopment;
